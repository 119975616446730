<template>
    <div :class="cssClass" :style="cssStyle">
        <div
            v-for="(option, index) in options"
            :ref="option.value"
            :key="option.value"
            @click="setValue(option.value)"
            :class="itemClass(option)"
        >
            <v-icon v-if="option.preIcon" size="18" class="item__pre-icon">{{ option.preIcon }}</v-icon>

            <div class="item__text">
                <slot name="prepend" :option="option" :index="index" />
                {{ option.text }}
                <slot name="append" :option="option" :index="index" />
            </div>

            <v-icon v-if="option.postIcon" size="18" class="item__post-icon">{{ option.postIcon }}</v-icon>
        </div>

        <div class="radio-text-group__selectect-indicator" ref="selectIndicator"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
        },
        modifier: {
            type: String,
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String, // small, medium, large
            default: 'small',
        },
        itemSizeType: {
            type: String, // sameWidth / auto
            default: 'auto',
        },
    },
    mounted() {
        this.updateSelectionElementPosition();
    },
    methods: {
        setValue(value) {
            this.$emit('input', value);
            this.$emit('change', value);
        },
        updateSelectionElementPosition() {
            this.$nextTick(() => {
                const option = this.options.find((o) => o.value === this.value);
                if (!option) return;

                const el = this.$refs[option.value]?.[0];
                const selectIndicator = this.$refs.selectIndicator;

                const elHeight = el.offsetHeight;
                const elWidth = el.offsetWidth;
                const elTop = el.offsetTop;
                const elLeft = el.offsetLeft;

                selectIndicator.style.height = `${elHeight}px`;
                selectIndicator.style.width = `${elWidth}px`;
                selectIndicator.style.top = `${elTop}px`;
                selectIndicator.style.left = `${elLeft}px`;
            });
        },
        itemClass(option) {
            return {
                item: true,
                'item--selected': option.value === this.value,
                [`item--${this.size}`]: true,
                [`item--${this.modifier}`]: this.modifier,
            };
        },
    },
    computed: {
        cssClass() {
            return {
                'radio-text-group': true,
                [`radio-text-group--${this.modifier}`]: this.modifier,
                [`radio-text-group--${this.size}`]: true,
                'radio-text-group--full-width': this.fullWidth,
            };
        },
        cssStyle() {
            const itemSizeType = this.itemSizeType === 'sameWidth' ? '1fr' : 'auto';

            return {
                gridTemplateColumns: this.fullWidth ? `repeat(${this.options.length}, ${itemSizeType})` : null,
            };
        },
    },
    watch: {
        value() {
            this.updateSelectionElementPosition();
        },
    },
};
</script>

<style scoped lang="scss">
.radio-text-group {
    position: relative;
    display: flex;
    padding: 4px;
    border-radius: 12px;
    background: #f1f2f5;

    &__selectect-indicator {
        background-color: white;
        border-radius: 10px;
        position: absolute;
        transition: ease-in-out 0.2s;
    }

    &--full-width {
        display: grid;
    }
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 12px;
    z-index: 1;

    cursor: pointer;
    transition: 0.4s;

    &__text {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
    }

    &__pre-icon,
    &__post-icon {
        color: inherit;
    }

    &--small {
        padding: 6px 18px;
    }

    &--medium {
        padding: 8px 18px;
    }

    &--large {
        padding: 12px 18px;
    }

    &--selected {
        color: var(--v-primary-base);
    }

    &--messenger {
        height: 32px;
    }
}
</style>
